import { NextPage } from "next";
import Head from "next/head";

const Error404: NextPage = () => {
    return (
        <>
            <Head>
                <title>404 | UXF</title>
            </Head>
            <main
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    padding: "1em",
                    textAlign: "center",
                }}
            >
                <h1 style={{ fontSize: "4em" }}>404</h1>
                <p style={{ fontSize: "2em" }}>Stránka nenalezena / Page not found</p>
            </main>
        </>
    );
};

export default Error404;
